<template>
    <div class="ecart-banner">
        <img :src="item.image" class="banner" v-bind:style="getStyles" v-if="item.image">
        <img v-image="item.image_url" class="banner" v-bind:style="getStyles" v-else>
        <!-- <pre> {{item}} </pre> -->
    </div>
</template>

<script>
    export default {
        props: {
            item: {
                type: Object,
            }
        },
        computed: {
            getStyles() {
                if (this.item) {
                    return {
                        height: (this.item.height <= 200 && this.item.height >= 50 ) ?  this.item.height + 'px' : '200px' ,
                        ['background-color']: this.item.bgColor,
                        ['object-fit']: this.item.bannerType,
                        ['background-repeat']: this.item.repeat,
                    }
                } else {
                    return null
                }
            }
        }
    }
</script>

<style lang="scss">
    .ecart-banner {

        // width: 100%;
        .banner {
            width: 100%;
            // min-height: 50px;
            // max-height: 300;
            object-fit: contain;
            // background-color: white;
            // background-repeat: no-repeat;
        }
    }
</style>